<template>
  <!-- 订单详情 -->
  <div class="orderDetail_wrapper">
    <div class="titleBar" v-if="isApp"></div>

    <div class="pageTitle">
      <div class="title_icon" @click="$router.go(-1)">
        <span class="icon-left"></span>
      </div>
      <div class="title_txt">
        <span>订单详情</span>
      </div>
    </div>
    <div class="orderBScroll" ref="orderBScroll">
      <div class="orderDetail_box">
        <div class="order">
          <div class="order_item">
            <div class="item_label">
              车场名称
            </div>
            <div class="item_content">{{ orderObj.parkingName }}</div>
          </div>

          <div class="order_item">
            <div class="item_label">
              车位号
            </div>
            <div class="item_content">{{ orderObj.parkingNumber }}</div>
          </div>

          <div class="order_item">
            <div class="item_label">
              车牌号
            </div>
            <div class="item_content">{{ orderObj.plateNumber }}</div>
          </div>

          <div class="order_item">
            <div class="item_label">
              入场时间
            </div>
            <div class="item_content">
              {{ orderObj.enterTime | dateFilter_yy_MM_dd_hh_mm_ss }}
            </div>
          </div>

          <div class="order_item">
            <div class="item_label">
              出场时间
            </div>
            <div class="item_content">
              {{ orderObj.exitOperatorTime | dateFilter_yy_MM_dd_hh_mm_ss }}
            </div>
          </div>

          <div class="order_item">
            <div class="item_label">
              停车时长
            </div>
            <div class="item_content">{{ orderObj.timeDifference }}</div>
          </div>

          <div class="order_item">
            <div class="item_label">
              订单编号
            </div>
            <div class="item_content">{{ orderObj.parkingOrderId }}</div>
          </div>

          <div class="order_item">
            <div class="item_label">
              订单金额
            </div>
            <div class="item_content">{{ orderObj.orderAmount }}元</div>
          </div>

          <div class="order_item" v-if="orderObj.vehicleType == 40">
            <div class="item_label">
              欠费金额
            </div>
            <div class="item_content">{{ orderObj.arrearAmount }}元</div>
          </div>

          <div class="order_item" v-else>
            <div class="item_label">
              实收金额
            </div>
            <div class="item_content">{{ orderObj.payAmount }} 元</div>
          </div>

          <div class="order_pic" v-for="(v, i) in picList" :key="i">
            <img :src="v" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BScroll from "better-scroll";
import { mapState } from "vuex";
export default {
  data: () => ({
    orderObj: {},
    picList: [] //图片列表
  }),
  computed: {
    ...mapState({
      isApp: state => state.isApp
    })
  },
  created() {
    this.orderObj = this.$route.params;

    //遍历订单对象，取出图片
    for (let k in this.orderObj) {
      if (k.indexOf("picture") != -1 && this.orderObj[k]) {
        this.picList.push(this.orderObj[k]);
      }
    }

    console.log("picList ==> ", this.picList);
  },
  mounted() {
    this.$nextTick(() => {
      this.$tools.entering();

      //初始化BScroll
      if (!this.orderBScroll) {
        this.orderBScroll = new BScroll(this.$refs.orderBScroll, {
          probeType: 2
        });
      } else {
        this.orderBScroll.refresh();
      }
    });
  }
};
</script>

<style lang="less" scoped>
.orderDetail_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.orderBScroll {
  width: 100%;
  flex: 1;
  overflow: hidden;
  background-color: #edf0f7;
  .orderDetail_box {
    background-color: #edf0f7;
    padding: 12px;

    .order {
      border-radius: 6px;
      background-color: #fff;
      padding: 0 12px;

      .order_item {
        height: 58px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        border-bottom: 1px solid rgba(7, 17, 27, 0.1);

        .item_label {
          color: #333;
        }

        .item_content {
          color: #000;
        }
      }
    }
  }
}

.order_pic {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
